import * as React from 'react';

import palette from '../../theme/_palette';

type SVGProps = {
    color?: string;
    width?: number;
    height?: number;
};

const SvgAndersenLogo = (props: SVGProps) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 703 79" fill="none" {...props}>
            <g clipPath="url(#clip0_152_12009)" fill={props.color ?? palette.white}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 76.16L32.18 0h.6l32.19 76.17H52.66L45.5 58.01H18.04l-4.89 11.92a10.037 10.037 0 01-9.29 6.23H0zm32.03-52.28L21.84 48.72h19.99l-9.8-24.84z"
                />
                <path d="M151.9 54.13L98.75.61h-.5v66.37a9.18 9.18 0 009.18 9.18h1.71l-.29-50.35 53.25 52.87h.3V2.93h-10.69l.19 51.2z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M254.55 15.03c-3.13-3.63-7.25-6.56-12.36-8.78-5.11-2.22-11.3-3.33-18.56-3.33h-20.38v64.05c0 5.07 4.11 9.19 9.19 9.19h14.72c4.91 0 9.55-.82 13.92-2.47 4.37-1.65 8.24-4.03 11.6-7.16 3.36-3.13 6.02-6.87 7.97-11.25 1.95-4.37 2.93-9.28 2.93-14.73 0-4.5-.72-8.98-2.17-13.42-1.45-4.44-3.73-8.47-6.86-12.1zm-4.09 35.81c-1.35 3.23-3.24 5.95-5.7 8.17-2.46 2.22-5.35 3.9-8.68 5.04-3.33 1.14-6.98 1.71-10.94 1.71h-11.1V13.32h12.51c3.16 0 6.27.52 9.33 1.56 3.06 1.04 5.83 2.66 8.32 4.84 2.49 2.19 4.49 4.94 6 8.27 1.51 3.33 2.27 7.25 2.27 11.75 0 4.17-.67 7.87-2.02 11.1h.01z"
                />
                <path d="M343.77 44.59h-33.19v21.18h30.98a7.75 7.75 0 017.75 7.75v2.64h-49.53V2.93h48.12v10.39h-37.32V34.2h33.19v10.39z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M426.99 42.37a21.6 21.6 0 004.24-7.67 28.94 28.94 0 001.31-8.57c0-3.76-.65-7.08-1.97-9.94-1.31-2.86-3.13-5.26-5.45-7.21-2.32-1.95-5.11-3.45-8.37-4.49-3.26-1.04-6.81-1.56-10.64-1.56h-20.07v73.23h3.36c4.11 0 7.44-3.33 7.44-7.44V49.93h10.69c.6 0 1.19-.01 1.77-.03l16.89 26.26h12.51l-18.12-28.91c2.58-1.3 4.74-2.91 6.42-4.88h-.01zm-30.15-29.05h11.09c2.56 0 4.71.44 6.46 1.31 1.75.87 3.14 1.98 4.19 3.33 1.04 1.35 1.78 2.76 2.22 4.24.44 1.48.66 2.89.66 4.24 0 1.41-.22 2.87-.66 4.39-.44 1.51-1.18 2.93-2.22 4.24-1.04 1.31-2.49 2.41-4.34 3.28-1.85.87-4.15 1.31-6.91 1.31h-10.49V13.32z"
                />
                <path d="M504.31 37.27c2.18 1.11 4.17 2.48 5.95 4.09v-.01c1.78 1.61 3.2 3.61 4.24 6s1.56 5.3 1.56 8.73c0 3.9-.95 7.43-2.87 10.59-1.91 3.16-4.67 5.66-8.27 7.51-3.6 1.85-7.95 2.77-13.06 2.77-3.1 0-6.1-.35-9.03-1.06-2.92-.7-5.68-1.64-8.27-2.82-2.59-1.17-4.93-2.47-7.01-3.88l1.47-2.55c1.78-3.09 5.66-4.18 8.85-2.58l.06.03a35.31 35.31 0 006.51 2.47c2.25.61 4.38.91 6.4.91 2.22 0 4.41-.39 6.56-1.16 2.16-.77 3.94-1.98 5.35-3.63 1.41-1.65 2.12-3.82 2.12-6.51 0-2.29-.63-4.22-1.87-5.8-1.24-1.58-2.89-2.95-4.94-4.09a47.01 47.01 0 00-6.61-3.03c-2.29-.88-4.61-1.85-6.96-2.93a35.192 35.192 0 01-6.56-3.88c-2.02-1.51-3.65-3.4-4.89-5.65-1.25-2.25-1.87-5.02-1.87-8.32 0-3.9.92-7.31 2.77-10.24 1.85-2.93 4.42-5.23 7.72-6.91 3.3-1.68 7.1-2.62 11.4-2.82 4.91 0 9.18.61 12.81 1.82 3.63 1.21 6.86 2.66 9.68 4.34L514.38 11a6.932 6.932 0 01-8.65 3.4c-.84-.31-1.7-.61-2.58-.89-2.96-.94-5.81-1.41-8.57-1.41-3.77 0-6.76.86-8.98 2.57-2.22 1.71-3.33 4.05-3.33 7.01 0 2.09.74 3.9 2.22 5.45 1.48 1.54 3.4 2.89 5.75 4.03 2.35 1.15 4.84 2.22 7.46 3.23 2.22.81 4.42 1.77 6.61 2.88zM562.97 44.59h33.19V34.2h-33.19V13.32h37.32V2.93h-48.12v73.23h49.53V73.9a8.13 8.13 0 00-8.13-8.13h-30.6V44.59zM692.07 54.15l-.19-51.22h10.69v75.75h-.3l-53.25-52.87.29 50.35h-2.16c-4.82 0-8.73-3.91-8.73-8.73V.61h.5l53.15 53.54z" />
            </g>
            <defs>
                <clipPath id="clip0_152_12009">
                    <path fill={props.color ?? palette.white} d="M0 0H702.58V78.68H0z" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default React.memo(SvgAndersenLogo);
