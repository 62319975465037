import type { NextPage } from 'next';
import Head from 'next/head';
import styles from '../styles/Home.module.css';
import SvgAndersenLogo from './components/SvgAndersenLogo';

const Home: NextPage = () => {
    return (
        <div className={styles.container}>
            <Head>
                <title>Andersen EV</title>
                <meta name="description" content="Andersen EV" />
            </Head>

            <main className={styles.main}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingBottom: 20,
                    }}
                >
                    <SvgAndersenLogo height={40} width={352} />
                </div>
                <h1 className={styles.title}>
                    Welcome to <a href="https://andersen-ev.com">Andersen EV</a>
                </h1>
            </main>
        </div>
    );
};

export default Home;
